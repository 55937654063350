import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import smartParkService from "../../axios";
import Link from "@material-ui/core/Link";
// nodejs library that concatenates classes
import { makeStyles } from "@material-ui/core/styles";
import {
  Edit,
  Delete,
  Add,
  ToggleOn,
  ToggleOff,
  DeleteOutline,
} from "@material-ui/icons";
import "../../assets/scss/App.scss";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import styles from "../../components/components.js";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { login_ } from "../../reducers/login";
import { paperAutoAdjustIntervalInMS } from "../../config/index";
import {
  permissions_,
  Getaccesspermissions,
  hasLoadedpermissions_,
} from "../../reducers/permissions";
import _ from "lodash";

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

const useStyles = makeStyles(styles);

function formatDate(dateVal) {
  if (!dateVal) return "";

  var newDate = new Date(dateVal);

  var sMonth = padValue(newDate.getMonth() + 1);
  var sDay = padValue(newDate.getDate());
  var sYear = newDate.getFullYear();
  var sHour = newDate.getHours();
  var sMinute = padValue(newDate.getMinutes());
  var sAMPM = "AM";

  var iHourCheck = parseInt(sHour);

  if (iHourCheck > 12) {
    sAMPM = "PM";
    sHour = iHourCheck - 12;
  } else if (iHourCheck === 0) {
    sHour = "12";
  }

  sHour = padValue(sHour);

  return (
    sMonth +
    "/" +
    sDay +
    "/" +
    sYear +
    " " +
    sHour +
    ":" +
    sMinute +
    " " +
    sAMPM
  );
}

function padValue(value) {
  return value < 10 ? "0" + value : value;
}

export default function UserDashboard(props) {
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const userid = _.get(loginDetails, ["UserID"], "");
  const Role = _.get(loginDetails, ["Role"], "");
  const [loading, setLoading] = React.useState(true);
  const [color, setColor] = React.useState("#ffffff");
  const [load, setload] = React.useState(false);
  const [selectionrows, setselectionrows] = React.useState([]);
  const classes = useStyles();
  const history = useHistory();
  const [permission, setpermission] = React.useState({});

  const DeleteUser = (id) => {
    if (permission.delete == 1) {
      confirmAlert({
        message: "Are you sure you want to delete?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              smartParkService
                .delete("api/users/" + id, {
                  headers: {
                    userid: userid,
                  },
                })
                .then((res, err) => {
                  if (res) {
                    setload(load == true ? false : true);
                    alert("Record Deleted Successfully");
                  }
                })
                .catch(() => {
                  alert("Error occurred while deleting the user");
                });
            },
          },
          {
            label: "No",
            onClick: () => { },
          },
        ],
      });
    } else {
      alert("You do not have permission to delete user");
    }
  };
  const Deletemultiple = () => {
    confirmAlert({
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            smartParkService
              .delete("api/users", {
                headers: {
                  userid: userid,
                },
                data: {
                  selectionrows: selectionrows,
                },
              })
              .then((res, err) => {
                setload(load == true ? false : true);
              })
              .catch(() => {
                alert("Error occurred while deleting the users");
              });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };
  const Enable = () => {
    console.log(selectionrows);
    smartParkService
      .post(
        "api/user/enable",
        {
          data: {
            selectionrows: selectionrows,
          },
        },
        {
          headers: {
            userid: userid,
          },
        }
      )
      .then((res, err) => {
        if (res.data.status == 200) {
          alert("Users Enabled");
          setload(!load);
        }
      });
  };
  const Disable = () => {
    console.log(selectionrows);
    smartParkService
      .post(
        "api/user/disable",
        {
          data: {
            selectionrows: selectionrows,
          },
        },
        {
          headers: {
            userid: userid,
          },
        }
      )
      .then((res, err) => {
        if (res.data.status == 200) {
          alert("Users Disabled");
          setload(!load);
        }
      });
  };
  const getpermissions = async (data) => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "UserManagment";
    });
    setpermission(Dpermissions[0]);
  };
  const columns = [
    { field: "FirstName", headerName: "First Name", width: 200 },
    { field: "LastName", headerName: "Last Name", width: 200 },
    { field: "Email", headerName: "Email", width: 250 },
    {
      field: "UserType",
      headerName: "UserType",
      width: 220,
      valueFormatter: (params) => {
        const rolesobj = params.row.UserType;
        if (rolesobj) {
          const keys = Object.keys(rolesobj).filter(
            (k) => rolesobj[k] === true
          );
          return keys.join(",");
        } else {
          return "No Roles";
        }
      },
    },
    { field: "Authentication", headerName: "Authentication", width: 150 },
    {
      field: "Login_Date",
      headerName: "Last Login Date",
      width: 250,
      valueFormatter: (params) => {
        return formatDate(params.value);
      },
    },
    { field: "Status", headerName: "Status", width: 130 },
    {
      field: "Actions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => (
        <div>
          {permission.delete == 1 ? (
            <Delete
              style={{
                color: "#03354b",
                fontSize: "1.2rem",
                cursor: "pointer",
              }}
              onClick={() => {
                DeleteUser(params.row.UserID);
              }}
            />
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];
  const [rows, setrows] = React.useState([]);
  //UsersIDs
  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);

  React.useEffect(() => {
    if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
      getpermissions();
    }
  }, [permissionsHasLoaded]);

  useEffect(() => {
    smartParkService.get("api/users/" + parkid).then((res) => {
      if (res) {
        setrows(res.data.data);
      } else {
      }
    });
  }, [load]);

  setTimeout(() => {
    var element = document.getElementsByClassName("usersGrid__SmartPark");
    if (element && element.length > 0) {
      var calculatedHeight = element[0].clientHeight + 25 + "px";
      document.getElementById("usersPaper__SmartPark").style.height =
        calculatedHeight;
    }
  }, paperAutoAdjustIntervalInMS);
  const routeAddUser = () => {
    let path = "AddUser";
    history.push(path);
  };
  return (
    <div className={classes.root}>
      {permission.read == 1 ? (
        <div className={classes.contentPage}>
          <section class="content-header">
            <h3 class="float-left">Users List</h3>
            <div class="float-right">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li class="breadcrumb-item">
                  <a class="active" href="#">
                    Users
                  </a>
                </li>
              </ol>
            </div>
          </section>
          <section class="content">
            <Grid container>
              <div class="content-middle" style={{ width: "95%" }}>
                <Grid item xs={12}>
                  <div class="btnRules">
                    {permission.create == 1 ? (
                      <Link component="button" onClick={routeAddUser}>
                        {" "}
                        <Add style={{ fontSize: 18 }} /> Add New User
                      </Link>
                    ) : (
                      ""
                    )}
                    {permission.update == 1 ? (
                      <Link component="button" onClick={Enable}>
                        {" "}
                        <ToggleOn style={{ fontSize: 18 }} /> &nbsp;Enable
                      </Link>
                    ) : (
                      ""
                    )}
                    {permission.update == 1 ? (
                      <Link component="button" onClick={Disable}>
                        {" "}
                        <ToggleOff style={{ fontSize: 18 }} /> &nbsp;Disable
                      </Link>
                    ) : (
                      ""
                    )}
                    {permission.delete == 1 ? (
                      <Link component="button" onClick={Deletemultiple}>
                        {" "}
                        <DeleteOutline style={{ fontSize: 18 }} /> Delete
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="usersPaper__SmartPark"
                    style={{ display: "flex", height: "auto" }}
                    className={classes.paper}
                  >
                    <div style={{ width: "100%" }}>
                      <div style={{ display: "flex", height: "auto" }}>
                        <div style={{ flexGrow: 1, cursor: "pointer" }}>
                          <DataGrid
                            className="usersGrid__SmartPark"
                            rows={rows}
                            columns={columns}
                            rowHeight={40}
                            headerHeight={45}
                            autoHeight={true}
                            hideFooter={true}
                            onSelectionModelChange={(e) => {
                              setselectionrows(e);
                            }}
                            onCellClick={(e) => {
                              if (e.field != "Actions")
                                history.push("/EditUser?id=" + e.row.UserID);
                            }}
                            checkboxSelection
                          />
                        </div>
                      </div>
                    </div>
                  </Paper>
                  {/* <div style={{ backgroundColor: "white" }}>
                    <DataGrid
                      className="MuiDataGrid-row"
                      rows={rows}
                      columns={columns}
                      rowHeight={40}
                      headerHeight={45}
                      autoHeight={true}
                      hideFooter={true}
                      onSelectionChange={(e) => {
                        setselectionrows(e.rowIds);
                      }}
                      onCellClick={(e) => {
                        if (e.field != "Actions")
                          history.push("/EditUser?id=" + e.row.UserID);
                      }}
                      checkboxSelection
                    />
                  </div> */}
                </Grid>
              </div>
            </Grid>
          </section>
        </div>
      ) : { loading } ? (
        <ClipLoader color={color} loading={loading} css={override} size={60} />
      ) : (
        <div
          style={{
            color: "red",
            position: "absolute",
            top: "50%",
            left: "40%",
            fontSize: "20px",
            transform: "translate(" - (50 % ", ") - (50 % ")"),
          }}
        >
          "You do not have read permissions. Please contact Admin"
        </div>
      )}
    </div>
  );
}
